import React from "react";
import { useTranslation } from "react-i18next";
import background from "../assets/background.png";
import portadaImage from "../assets/PortadaImage.png";
import googlePlaySvg from "../assets/googlePlay.svg";
import appStoreSvg from "../assets/appStoreSvg.svg";
import LINKS from "../config/links";
import {
  Container,
  BackgroundImage,
  JumboMan,
  TextContainer,
  TextContent,
  SecondTextContent,
  ImagesContainer,
  GooglePlayButton,
  AppStoreButton,
  VideoContainer,
  VideoIframe,
  Divider,
  SubTextContent,
} from "../styles/DashboardStyles";

const Dashboard = () => {
  const { t, i18n } = useTranslation();

  return (
    <Container>
      <BackgroundImage src={background} alt="background" />
      <JumboMan src={portadaImage} alt="jumboMan" />
      <TextContainer>
        <TextContent lang={i18n.language.split("-")[0]}>
          {t("titleDashboard")}
        </TextContent>
        <SubTextContent lang={i18n.language.split("-")[0]}>
          {t("subtitleDashboard")}
        </SubTextContent>

        <SecondTextContent>{t("downloadDescription")}</SecondTextContent>
      </TextContainer>
      <ImagesContainer>
        <a
          href={LINKS.GOOGLEPLAYSTORE}
          target="_blank"
          rel="noopener noreferrer"
        >
          <GooglePlayButton src={googlePlaySvg} alt="Google Play" />
        </a>
        <a href={LINKS.APPLESTORE} target="_blank" rel="noopener noreferrer">
          <AppStoreButton src={appStoreSvg} alt="App Store" />
        </a>
      </ImagesContainer>
      <Divider />
      <Divider />
      <VideoContainer>
        <VideoIframe
          src={LINKS.VIDEO}
          title="video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></VideoIframe>
      </VideoContainer>
    </Container>
  );
};

export default Dashboard;
