import styled from "styled-components";
import videoBackground from "../assets/videoBackground.svg";

export const Container = styled.div`
  position: relative;
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 575px;
`;

export const JumboMan = styled.img`
  position: absolute;
  top: 0%;
  left: 70%;
  transform: translateX(-50%);
  width: 760px;
  max-width: 100%;
  height: auto;

  @media (max-width: 1430px) {
    display: none;
  }
`;

export const TextContainer = styled.div`
  position: absolute;
  top: 50px;
  left: 92px;

  @media (max-width: 768px) {
    left: auto;
    top: 100px;
    width: 100%;
  }
`;

export const TextContent = styled.h1`
  font-family: "FCBARCELONA-Extrabold";
  font-size: 50px;
  text-align: left;
  color: white;
  margin-bottom: -3rem;

  @media (max-width: 768px) {
    width: 80%;
    font-size: 2.2rem;
    text-align: center;
    position: absolute;
    top: -100px;
    left: 40px;
    @media (max-width: 430px) {
      font-size: ${(props) => {
        switch (props.lang) {
          case "es":
            return "2.1rem";
          case "en":
            return "2.2rem";
          case "ca":
            return "2.2rem";
          case "pt":
            return "2.2rem";
          default:
            return "2.1rem";
        }
      }};
      top: ${(props) => {
        switch (props.lang) {
          case "es":
            return "-100px";
          case "en":
            return "-100px";
          case "ca":
            return "-100px";
          case "pt":
            return "-100px";
          default:
            return "-100px";
        }
      }};
    }
  }
`;

export const SubTextContent = styled.h1`
  font-family: "FCBARCELONA-Extrabold";
  font-size: 50px;
  text-align: left;
  color: white;

  @media (max-width: 768px) {
    width: 80%;
    font-size: 2.2rem;
    text-align: center;
    position: absolute;
    top: -60px;
    left: 40px;
  }

  @media (max-width: 430px) {
    font-size: ${(props) => {
      switch (props.lang) {
        case "es":
          return "2.1rem";
        case "en":
          return "2.1rem";
        case "ca":
          return "2.1rem";
        case "pt":
          return "2.3rem";
        default:
          return "2.1rem";
      }
    }};
    top: ${(props) => {
      switch (props.lang) {
        case "es":
          return "-20px";
        case "en":
          return "-15px";
        case "ca":
          return "-15px";
        case "pt":
          return "-50px";
        default:
          return "-20px";
      }
    }};
  }
`;

export const SecondTextContent = styled.h2`
  width: 537px;
  height: 58px;
  top: 317px;
  left: 138px;
  font-family: "FCBARCELONA-Bold";
  font-size: 24px;
  line-height: 28.97px;
  text-align: left;
  color: white;

  @media (max-width: 768px) {
    width: 68%;
    font-size: 1.3rem;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 55px;
    @media (max-width: 430px) {
      top: 70px;
      @media (max-width: 320px) {
        font-size: 1.2rem;
      }
    }
  }
`;

export const ImagesContainer = styled.div`
  position: absolute;
  top: 380px;
  left: 350px;
  transform: translateX(-50%);

  @media (max-width: 768px) {
    left: 46%;
    transform: translateX(-50%);
    top: 390px;
  }
`;

export const GooglePlayButton = styled.img`
  width: 267px;
  margin-right: 20px;
  height: auto;

  @media (max-width: 768px) {
    width: 250px;
    margin-left: 55px;
    margin-top: 5px;
  }
`;

export const AppStoreButton = styled.img`
  width: 237px;
  height: auto;

  @media (max-width: 768px) {
    width: 250px;
    margin-left: 55px;
    margin-top: 5px;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0rem;
  background-image: url(${videoBackground});
  padding: 10px;
`;

export const VideoIframe = styled.iframe`
  width: 100%;
  max-width: 1831px;
  height: 1047px;
  background-color: rgba(2, 29, 70, 0.3);

  @media (max-width: 768px) {
    height: 215px;
  }
`;

export const Divider = styled.div`
  position: absolute;
  background: linear-gradient(90deg, #cf122d 0%, #154284 100%);
  width: 100%;
  height: 10px;
  top: 570px;
  gap: 0px;
  opacity: 1;
`;
