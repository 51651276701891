// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0A0927;
    height: 74px;
    padding: 0 80px;
}

.navbar-container {
  width: 1276px;
  height: 56px;
  top: 9px;
  left: 82px;
  gap: 0px;
  border: 1px solid transparent;

}

.logo {
  width: 128px;
  height: 56px;
  top: 9px;
  left: 82px;
  gap: 0px;
}

.navbar {
  display: flex;
  gap: 40px;
}

.nav-button-login,
.nav-button-register {
  width: 184px;
  height: 44px;
  padding: 0;
  border-radius: 6px;
  border: 1px solid #92C4E9;
  display: flex;
  justify-content: center; 
  align-items: center; 
  background: #15428480;
  font-family: 'FCBARCELONA-Semibold';
  color: white;
  text-decoration: none;
  font-size: 15px;
  box-shadow: 0px 4px 4px 0px #00000040; 
}

.nav-button-register {
  background: #CF122D;
border: 0px;
}



@media (max-width: 768px) {
  .header {
    padding: 0 30px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/navbar.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;IACZ,eAAe;AACnB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,QAAQ;EACR,UAAU;EACV,QAAQ;EACR,6BAA6B;;AAE/B;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,QAAQ;EACR,UAAU;EACV,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;;EAEE,YAAY;EACZ,YAAY;EACZ,UAAU;EACV,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;EACrB,mCAAmC;EACnC,YAAY;EACZ,qBAAqB;EACrB,eAAe;EACf,qCAAqC;AACvC;;AAEA;EACE,mBAAmB;AACrB,WAAW;AACX;;;;AAIA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":["\n.header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: #0A0927;\n    height: 74px;\n    padding: 0 80px;\n}\n\n.navbar-container {\n  width: 1276px;\n  height: 56px;\n  top: 9px;\n  left: 82px;\n  gap: 0px;\n  border: 1px solid transparent;\n\n}\n\n.logo {\n  width: 128px;\n  height: 56px;\n  top: 9px;\n  left: 82px;\n  gap: 0px;\n}\n\n.navbar {\n  display: flex;\n  gap: 40px;\n}\n\n.nav-button-login,\n.nav-button-register {\n  width: 184px;\n  height: 44px;\n  padding: 0;\n  border-radius: 6px;\n  border: 1px solid #92C4E9;\n  display: flex;\n  justify-content: center; \n  align-items: center; \n  background: #15428480;\n  font-family: 'FCBARCELONA-Semibold';\n  color: white;\n  text-decoration: none;\n  font-size: 15px;\n  box-shadow: 0px 4px 4px 0px #00000040; \n}\n\n.nav-button-register {\n  background: #CF122D;\nborder: 0px;\n}\n\n\n\n@media (max-width: 768px) {\n  .header {\n    padding: 0 30px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
