const LINKS = {
  LOGIN:
    "https://id.fcbarcelona.com/fcb/s/login/?ec=302&inst=P8&startURL=%2Fculers%2Fsetup%2Fsecur%2FRemoteAccessAuthorizationPage.apexp%3Fsource%3DCAAAAY7YMK6BMDAwMDAwMDAwMDAwMDAwAAAA-OweuW6IVMuQwgH1d_EwcyX9lYRsxoS2RfG90y7_cW51Yae_c2RaJVzfIqp8LCKhS1EF4Kk98dkk63fbFcefpgKzftdsYdq005n1WHTyTGOR5zINWLYU9cHx-bTNpA3ATuUsNTJ6IBQ5VQKysmM5N4F6a0JATQ8RPu7ceKrE5IqLU3dAVuImgGxC8ERvbIj_V-O8zEM5xxMt7LJwc7-hQiM5ieESz-OjEI40lRJ9W10NiMsRR4W8W2Z3sjWpp4J8xkcGVoyahSPqLa8IlBrhfQP6PG-aB9u0_6N-ddBdeqwRH7ox9s5WTqgYilncHiovHdKeVd4SCyW-qOZ8ooKEdvVWAzmhj0lvlLmPl6x5cPyrHTFZ6zv_tUL_AVqEPefhOppv7AVWWO2o21CWHQOTPLB4FmE8O-_t7Y0_6R_28CSRNADUzThLhDDfoUvn7uaU2IzUJd_2Q2jPZssIXcvUZvodw4FCDkbZ-pAK6pfE1fo7KTKaxNu15i0E64VgGdJYk2n-Ix7fgkKuRRuDXsANaSCUgroRt35CfIxh7Sence7MLmU63wnfSbVp8d7BKyDqjCtcYDDy2PQfNyrq0hITXpB4RcD8_I02tJCX72b1n85zs9xWguhUZDzS3ILy4htPS1MJYWMv4U7ZtvWkLfcZjbGViQTnwDhmr5vDaSVO8g1RMq9UQ6v9fQK89xAtUg0yNjyD2NxB1S_1JjluKP5M-eNtu8bzuCCYNBFCiZZopG9TSgwQ2EE7nhQz93ZJ3gOD6LJs8Qs595D6CjD8qiAbTSUXeeHArZ0e-EcZ2f4EZ4qsASieZqmSt-Op3FLy9WbicVLPA2hO_y5v6isf-kgRhbDknzXZYKhApmp2MFG2uHqVmtY0wwUUA4ECeSJHin-lckJqEOIw8ClUG58vTAkntLMv1ncjGgNjc8fHJbnlj1IEuDOBA9qFAEUOBO9NsC_ByGA8-x4R18kjDbgxl9hFQy9mCA_IykY-lQRcQNUUw4Je",
  VIDEO: "https://www.youtube.com/embed/jFBs9baVvV4",
  GOOGLEPLAYSTORE:
    "https://play.google.com/store/apps/details?id=com.focgames.barcagames",
  APPLESTORE: "https://apps.apple.com/app/barça-games/id6504048354",
};

export default LINKS;
