import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import barcaIcon from "../assets/barcaIcon.png";
import barcaBurger from "../assets/barca-burger.svg";
import LINKS from "../config/links";
import {
  HeaderContainer,
  Logo,
  Navbar,
  MenuToggle,
  NavButtonLogin,
  BarcaIconMenu,
  PageOverlay,
  LanguageDropdown,
  LanguageButton,
  DropdownContent,
} from "../styles/HeaderStyles";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);

  const menuToggleRef = useRef(null);
  const navbarRef = useRef(null);

  const handleMenuToggle = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const handleLanguageDropdownToggle = () => {
    setIsLanguageDropdownOpen(!isLanguageDropdownOpen);
  };

  const handleLanguageChange = (languageCode) => {
    i18n.changeLanguage(languageCode);
    setIsLanguageDropdownOpen(false);
  };

  const getLanguageName = (languageCode) => {
    switch (languageCode) {
      case "es":
        return "ES";
      case "pt":
        return "PT";
      case "en":
        return "EN";
      case "ca":
        return "CA";
      default:
        return i18n.language.slice(0, 2).toUpperCase();
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isMenuOpen &&
        navbarRef.current &&
        !navbarRef.current.contains(event.target) &&
        menuToggleRef.current &&
        !menuToggleRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isMenuOpen]);

  return (
    <HeaderContainer>
      <Logo src={barcaIcon} alt="Barca Icon" />
      <MenuToggle
        ref={menuToggleRef}
        className={isMenuOpen ? "open" : ""}
        onClick={handleMenuToggle}
      >
        <span></span>
        <span></span>
        <span></span>
      </MenuToggle>
      {isMenuOpen && <PageOverlay />}
      <Navbar ref={navbarRef} isOpen={isMenuOpen}>
        <BarcaIconMenu src={barcaBurger} alt="Barca Icon" />
        {/* <NavButtonLogin href={LINKS.LOGIN}>{t("login")}</NavButtonLogin> */}
        {/* <NavButtonRegister>REGISTRATE</NavButtonRegister> */}
        <LanguageDropdown>
          <LanguageButton
            onClick={handleLanguageDropdownToggle}
            isOpen={isLanguageDropdownOpen}
          >
            {getLanguageName(i18n.language)}
          </LanguageButton>
          {isLanguageDropdownOpen && (
            <DropdownContent className={isLanguageDropdownOpen ? "show" : ""}>
              <a href="#" onClick={() => handleLanguageChange("es")}>
                Español
              </a>
              <a href="#" onClick={() => handleLanguageChange("en")}>
                English
              </a>
              <a href="#" onClick={() => handleLanguageChange("ca")}>
                Català
              </a>
              <a href="#" onClick={() => handleLanguageChange("pt")}>
                Portugues
              </a>
              {/* otros idiomas */}
            </DropdownContent>
          )}
        </LanguageDropdown>
      </Navbar>
    </HeaderContainer>
  );
};

export default Header;
