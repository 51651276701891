import styled from "styled-components";

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0a0927;
  height: 74px;
  padding: 0 80px;

  @media (max-width: 768px) {
    padding: 0 30px;
  }
`;

export const Logo = styled.img`
  width: 128px;
  height: 56px;
`;

export const Navbar = styled.nav`
  display: flex;
  gap: 40px;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 255px;
    height: 100vh;
    background-color: #154284;
    padding: 20px;
    z-index: 1000;
    box-shadow: -6px 0px 10px 0px #00000040;
  }
`;

export const MenuToggle = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  top: 20px;
  right: 20px;

  span {
    display: block;
    width: 25px;
    height: 3px;
    background: #fff;
    margin: 5px;
    transition: transform 0.4s ease, opacity 0.4s ease;
  }

  &.open span:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  &.open span:nth-child(2) {
    opacity: 0;
  }

  &.open span:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

export const NavButtonLogin = styled.a`
  width: 184px;
  height: 44px;
  padding: 0;
  border-radius: 6px;
  border: 1px solid #92c4e9;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #15428480;
  font-family: 'FCBARCELONA-Semibold';
  color: white;
  text-decoration: none;
  font-size: 15px;
  box-shadow: 0px 4px 4px 0px #00000040;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background-color: #378dd4;
    background-color: rgba(55, 141, 212, 0.5); /* #378DD4 50% */
  }

  &:active {
    background-color: #154284;
    border-color: #92c4e9;
  }

  &:disabled {
    background-color: rgba(167, 167, 167, 0.3); /* #A7A7A74D 30% */
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

export const NavButtonRegister = styled(NavButtonLogin)`
  background: #cf122d;
  border: 0px;
`;

export const BarcaIconMenu = styled.img`
  display: none;

  @media (max-width: 768px) {
    display: block;
    width: 70px;
    z-index: 1001;
    margin-bottom: 20px;
  }
`;

export const PageOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #181833b2;
  z-index: 998;
`;

export const LanguageDropdown = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

export const LanguageButton = styled.div`
  color: white;
  font-size: 16px;
  background-color: ${(props) => (props.isOpen ? "#CF122D" : "#27275D")};
  width: 44px;
  height: 44px; 
  border-radius: 100%;
  display: flex;
  font-family: 'Roboto';
  font-size: 16px; 
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${(props) => (props.isOpen ? "#CF122D" : "#0a0927")};
  }
`;

export const DropdownContent = styled.div`
  position: absolute;
  top: 100%;
  background-color: #181833;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
 display: none;
   &.show {
    display: block;  // Mostrar cuando el estado activo
  }  
  a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;

    &:hover {
      background-color: #27275d;
    }

    &:active {
      background-color: #4b4b85;
    }
  }

  .selected {
    background-color: #fdc42c;
    color: #181833;
  }

  @media (max-width: 1215px) {
    position: absolute;
    bottom: 100%;
    left: 0;
  }

    @media (max-width: 768px) {
      position: absolute;
      bottom: 50%;
      left: 50%;
  }
`;