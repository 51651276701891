import styled from "styled-components";

export const FooterContainer = styled.div`
  height: 194px;
  background-color: #0a0927;
  color: #ffffff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    width: auto;
    height: auto;
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  margin-top: 30px;

  @media (max-width: 1215px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 5%;
    margin-bottom: 30px;
    margin-left: 5%;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 20px;
    width: 100%;
  }
`;

export const BarcaContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

export const BarcaFooterImage = styled.img`
  width: 220px;
  max-height: 100%;
  margin-right: 100px;
  padding-top: 0.2rem;

  @media (max-width: 768px) {
    padding-left: 6.2rem;
  }
`;

export const LinkGroupContainer = styled.div`
  display: flex;
  gap: 70px;
  padding-top: 2.5rem;
  @media (max-width: 768px) {
    padding-left: 0.5rem;
  }
`;

export const LinkGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 70px;

  @media (max-width: 1215px) {
    margin-right: 30px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

export const FooterLink = styled.a`
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  margin-bottom: 30px;
  text-decoration: none;
  color: #7d7d7d;

  &:hover {
    color: #a7a7a7;
  }

  &:active {
    color: #fdc42c;
  }
`;

export const SubFooterLink = styled(FooterLink)`
  color: white;
`;

export const SocialIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0.4rem;
  margin-left: 4rem;
  @media (max-width: 768px) {
    width: 100%;
    padding-right: 5rem;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: 1215px) {
    margin-top: 20px;
    width: 100%;
    justify-content: center;
  }

  @media (max-width: 768px) {
    gap: 40px;
  }
`;

export const SocialIcon = styled.img`
  width: 40px;
  height: auto;
  margin-left: 30px;
  filter: brightness(0) invert(1) sepia(1) saturate(1) hue-rotate(0deg)
    brightness(90%) opacity(0.5);
  transition: filter 0.3s ease;

  &:hover {
    filter: brightness(0) invert(1) sepia(1) saturate(1) hue-rotate(0deg)
      brightness(90%) opacity(0.8);
  }

  &:active {
    filter: brightness(0) invert(1) sepia(1) saturate(1) hue-rotate(0deg)
      brightness(90%) opacity(1);
  }

  @media (max-width: 1215px) {
    margin-left: 15px;
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
  }
`;

export const FooterBottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 140px;
  font-size: 10px;
  font-family: "Roboto";
  @media (max-width: 1215px) {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    position: relative;
    bottom: 20px;
    left: 0;
    right: 0;
    background-color: darkblue;
  }
`;

export const FooterText = styled.div`
  color: #ffffff;

  @media (max-width: 768px) {
    padding: 0;
    margin-bottom: 10px;
  }
`;

export const BarcaGames = styled.h1`
  text-align: left;
  font-size: 16px;
  margin-bottom: -10px;
  @media (max-width: 768px) {
    margin-left: 0;
    font-size: 1.5em;
  }
`;

export const BarcaGamesVersion = styled.h1`
  text-align: left;
  font-size: 10px;
  color: gray;
  @media (max-width: 768px) {
    margin-left: 0;
    font-size: 0.8em;
  }
`;

export const Show = styled.div`
  display: block;
`;
