import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import barcaBurger from "../svg/barca-burger.svg";
import barcaIcon from "../svg/barcaIcon.png";
import barcaSingleIcon from "../svg/barca-burger.svg";
import "../styles/navbar.css";
import "../styles/terms.css";

function Terms() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isMenuOpen && !event.target.closest(".navbar")) {
        setIsMenuOpen(true);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isMenuOpen]);

  return (
    <div>
      {isMenuOpen && (
        <div className="page-overlay" onClick={handleMenuToggle}></div>
      )}
      <header className="header">
      <Link to="/">
        <img src={barcaIcon} alt="Barca Icon" className="logo" />
      </Link>
        <button className="menu-toggle" onClick={handleMenuToggle}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <nav className={`navbar ${isMenuOpen ? "open" : ""}`}>
          <img src={barcaBurger} alt="Barca Icon" className="barca-icon-menu" />
        </nav>
      </header>
      <div className="terms-conditions-background">
        <h1 className="terms-title">TACTICAL STRIKERS TERMS OF USE</h1>
        <p className="terms-paragraph">
          Version 1.0 <br />
          The Tactical Strikers website located at http://tactical-strikers.com
          is a copyrighted work belonging to Tactical Strikers. Certain features
          of the Site may be subject to additional guidelines, terms, or rules,
          which will be posted on the Site in connection with such features. All
          such additional terms, guidelines, and rules are incorporated by
          reference into these Terms. These Terms of Use describe the legally
          binding terms and conditions that oversee your use of the Site. BY
          LOGGING INTO THE SITE, YOU ARE BEING COMPLIANT THAT THESE TERMS and
          you represent that you have the authority and capacity to enter into
          these Terms. YOU SHOULD BE AT LEAST 18 YEARS OF AGE TO ACCESS THE
          SITE. IF YOU DISAGREE WITH ALL OF THE PROVISION OF THESE TERMS, DO NOT
          LOG INTO AND/OR USE THE SITE. These terms require the use of
          arbitration Section 10.2 on an individual basis to resolve disputes
          and also limit the remedies available to you in the event of a
          dispute.
        </p>
        <h1 className="terms-sub-title">
          <img src={barcaSingleIcon} alt="Barca Icon" className="barca-icon" />
          Access to the site
        </h1>
        <p className="terms-paragraph">
          Subject to these Terms. Tactical Strikers grants you a
          non-transferable, non-exclusive, revocable, limited license to access
          the Site solely for your own personal, noncommercial use. Certain
          Restrictions. The rights approved to you in these Terms are subject to
          the following restrictions: (a) you shall not sell, rent, lease,
          transfer, assign, distribute, host, or otherwise commercially exploit
          the Site; (b) you shall not change, make derivative works of,
          disassemble, reverse compile or reverse engineer any part of the Site;
          (c) you shall not access the Site in order to build a similar or
          competitive website; and (d) except as expressly stated herein, no
          part of the Site may be copied, reproduced, distributed, republished,
          downloaded, displayed, posted or transmitted in any form or by any
          means unless otherwise indicated, any future release, update, or other
          addition to functionality of the Site shall be subject to these
          Terms.  All copyright and other proprietary notices on the Site must
          be retained on all copies thereof.
        </p>
        <h1 className="terms-sub-title">
          <img src={barcaSingleIcon} alt="Barca Icon" className="barca-icon" />
          Access to the site
        </h1>
        <p className="terms-paragraph">
          Subject to these Terms. Tactical Strikers grants you a
          non-transferable, non-exclusive, revocable, limited license to access
          the Site solely for your own personal, noncommercial use. Certain
          Restrictions. The rights approved to you in these Terms are subject to
          the following restrictions: (a) you shall not sell, rent, lease,
          transfer, assign, distribute, host, or otherwise commercially exploit
          the Site; (b) you shall not change, make derivative works of,
          disassemble, reverse compile or reverse engineer any part of the Site;
          (c) you shall not access the Site in order to build a similar or
          competitive website; and (d) except as expressly stated herein, no
          part of the Site may be copied, reproduced, distributed, republished,
          downloaded, displayed, posted or transmitted in any form or by any
          means unless otherwise indicated, any future release, update, or other
          addition to functionality of the Site shall be subject to these
          Terms.  All copyright and other proprietary notices on the Site must
          be retained on all copies thereof.
        </p>
        <h1 className="terms-sub-title">
          <img src={barcaSingleIcon} alt="Barca Icon" className="barca-icon" />
          Contact Information
        </h1>
        <p className="terms-paragraph">
          {" "}
          Adress: Chaco 1670, CP 7600 <br />
          Email: contact@tacticalstrikers.net
        </p>
        <div className="buttons-terms">
          <button className="button-term-red">
            ACEPTAR TÉRMINOS Y CONDICIONES
          </button>
          <button className="button-term-blue">RECHAZAR</button>
        </div>
      </div>
    </div>
  );
}

export default Terms;
