// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/FCBARCELONA-Semibold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/FCBARCELONA-Extrabold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/FCBARCELONA-Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/FCBARCELONA-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./assets/FCBARCELONA-Light.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@font-face {
  font-family: FCBARCELONA-Semibold;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
  font-family: FCBARCELONA-Extrabold;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
  font-family: FCBARCELONA-Bold;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@font-face {
  font-family: FCBARCELONA-Regular;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}

@font-face {
  font-family: FCBARCELONA-Light;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,iCAAiC;EACjC,4CAA6C;AAC/C;;AAEA;EACE,kCAAkC;EAClC,4CAA8C;AAChD;;AAEA;EACE,6BAA6B;EAC7B,4CAAyC;AAC3C;;AAEA;EACE,gCAAgC;EAChC,4CAA4C;AAC9C;;AAEA;EACE,8BAA8B;EAC9B,4CAA0C;AAC5C;;AAIA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@font-face {\n  font-family: FCBARCELONA-Semibold;\n  src: url(\"./assets/FCBARCELONA-Semibold.otf\");\n}\n\n@font-face {\n  font-family: FCBARCELONA-Extrabold;\n  src: url(\"./assets/FCBARCELONA-Extrabold.otf\");\n}\n\n@font-face {\n  font-family: FCBARCELONA-Bold;\n  src: url(\"./assets/FCBARCELONA-Bold.otf\");\n}\n\n@font-face {\n  font-family: FCBARCELONA-Regular;\n  src: url(\"./assets/FCBARCELONA-Regular.otf\");\n}\n\n@font-face {\n  font-family: FCBARCELONA-Light;\n  src: url(\"./assets/FCBARCELONA-Light.otf\");\n}\n\n@import url(\"https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap\");\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
