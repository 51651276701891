import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import instagramIcon from "../assets/ig.svg";
import twitterIcon from "../assets/tw.svg";
import tiktokIcon from "../assets/tiktokIcon.svg";
import barcaFooter from "../assets/barcaFooter.svg";
import {
  FooterContainer,
  FooterLinks,
  BarcaFooterImage,
  LinkGroup,
  SubFooterLink,
  SocialIcons,
  SocialIcon,
  BarcaGames,
  BarcaContainer,
  SocialIconsContainer,
  LinkGroupContainer,
  BarcaGamesVersion,
} from "../styles/FooterStyles";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(i18n.language.split("-")[0]);

  useEffect(() => {
    const handleLanguageChange = (lng) => {
      setCurrentLang(lng.split("-")[0]);
    };
    i18n.on("languageChanged", handleLanguageChange);
    handleLanguageChange(i18n.language);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);

  if (!i18n.isInitialized) {
    return <div>Loading...</div>;
  }

  const links = {
    cookies: {
      es: "https://www.fcbarcelona.es/es/cookies-barca-games",
      en: "https://www.fcbarcelona.com/en/cookies-barca-games",
      ca: "https://www.fcbarcelona.cat/ca/cookies-barca-games",
      pt: "https://www.fcbarcelona.es/en/cookies-barca-games",
    },
    privacy: {
      es: "https://www.fcbarcelona.es/es/politica-privacidad",
      en: "https://www.fcbarcelona.com/en/privacy-policy",
      ca: "https://www.fcbarcelona.cat/ca/politica-privacitat",
      pt: "https://www.fcbarcelona.com/en/privacy-policy",
    },
    terms: {
      es: "https://www.fcbarcelona.es/es/condiciones-de-uso-barca-games",
      en: "https://www.fcbarcelona.com/en/terms-of-use-barca-games",
      ca: "https://www.fcbarcelona.cat/ca/condicions-us-barca-games",
      pt: "https://www.fcbarcelona.com/en/terms-of-use-barca-games",
    },
    contact: {
      es: "https://www.fcbarcelona.es/es/club/contacto",
      en: "https://www.fcbarcelona.com/en/club/contacto",
      ca: "https://www.fcbarcelona.com/ca/club/contacto",
      pt: "https://www.fcbarcelona.com/en/club/contacto",
    },
  };

  return (
    <FooterContainer>
      <FooterLinks>
        <BarcaContainer>
          <BarcaFooterImage src={barcaFooter} alt="Barca Icon" />
          <BarcaGames>© FOC Games SL</BarcaGames>
          <BarcaGamesVersion>v1.0.0</BarcaGamesVersion>
        </BarcaContainer>
        <LinkGroupContainer>
          <LinkGroup>
            <SubFooterLink href={links.contact[currentLang]}>
              {t("contact")}
            </SubFooterLink>
            <SubFooterLink href={links.cookies[currentLang]}>
              {t("cookies")}
            </SubFooterLink>
            <SubFooterLink href={links.privacy[currentLang]}>
              {t("privacyPolicy")}
            </SubFooterLink>
            <SubFooterLink as={Link} to={links.terms[currentLang]}>
              {t("terms")}
            </SubFooterLink>
          </LinkGroup>
        </LinkGroupContainer>
        <SocialIconsContainer>
          <SocialIcons>
            <a
              href="https://www.instagram.com/gamesfcb"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon src={instagramIcon} alt="Instagram Icon" />
            </a>
            <a
              href="https://x.com/gamesfcb"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon src={twitterIcon} alt="Twitter Icon" />
            </a>
            <a
              href="https://www.tiktok.com/@gamesfcb"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon src={tiktokIcon} alt="TikTok Icon" />
            </a>
          </SocialIcons>
        </SocialIconsContainer>
      </FooterLinks>
    </FooterContainer>
  );
};

export default Footer;
